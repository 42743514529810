#components-layout-demo-top-side-2 .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top-side-2 .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.site-layout-background {
  background: #fff;
}

.ant-layout {
  height: 100%;
}

/* .ant-menu-submenu-popup {
  display: none;
} */

.self-drawer {
  position: absolute;
  z-index: 1000;
  left: 0px;
  height: 100vh;
}

.after-self-draw {
  position: absolute;
  inset: 0;
  z-index: 999;
  background: rgba(0,0,0,.45);
  pointer-events: auto;
}